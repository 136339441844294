import request from '@/utils/request'

/**
 * 敏感词
 */
export class SensitiveWordsMonitor {
  // 敏感词列表
  static index (params) {
    return request({
      url: '/sensitiveWordsMonitor/index',
      method: 'get',
      params
    })
  }

  // 敏感词列表
  static show (params) {
    return request({
      url: '/sensitiveWordsMonitor/show',
      method: 'get',
      params
    })
  }
}