<template>
  <div class="index">
    <van-nav-bar
        title="企业风控"
        left-arrow
        fixed
        placeholder
        @click-left="onClickLeft"
    />
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <div class="list-item" v-for="(item, index) in list" :key="index">
          <div class="intro">
            <div>
              <div class="label">敏感词</div>
              <div class="content" style="color: #ee0e24">{{ item.sensitiveWordName }}</div>
            </div>
            <div>
              <div class="label">来源</div>
              <div class="content"><van-icon name="user-o" color="#2b63cd" v-show="item.sourceText == '员工'" /> {{ item.sourceText }}</div>
            </div>
            <div>
              <div class="label">触发者</div>
              <div class="content">{{ item.triggerName }}</div>
            </div>
            <div>
              <div class="label">触发场景</div>
              <div class="content">{{ item.triggerScenario }}</div>
            </div>
          </div>
          <div class="footer">
            <div>触发时间：{{ item.triggerTime }}</div>
            <div>
              <van-button class="fs14" type="info" size="mini" @click="check(item)">查看对话</van-button>
            </div>
          </div>
        </div>
        <div v-if="finished && list.length" slot="finished">无更多数据</div>
      </van-list>
    </div>
    <!--  -->
    <van-dialog v-model="showChatRecord" title="聊天记录" confirm-button-color="#1989fa">
      <div class="chatRecord">
        <div v-if="chatRecord && chatRecord.length">
          <div v-for="(item, index) in chatRecord" :key="index" class="sendBox">
            <div class="contcat">发送人：{{ item.sender }} <span>{{ item.sendTime }}</span></div>
            <div class="msg">
              <div v-show="item.isTrigger" style="color: #ee0e24"><van-icon name="warning-o" color="#ee0e24" /> 触发敏感词</div>
              <div v-if="item.msgContent.type == 'text'">{{ item.msgContent.content }}</div>
              <div v-if="item.msgContent.type == 'link'">
                <a class="link" :href="item.msgContent.link_url">
                  <img :src="item.msgContent.image_url" />
                  <div>
                    {{ item.msgContent.title }}
                    <p>
                      {{ item.msgContent.description }}
                    </p>
                  </div>
                </a>
              </div>
              <div v-if="item.msgContent.type == 'image'" class="image">
                <img :src="item.msgContent.imageFullPath" />
              </div>
              <div v-if="item.msgContent.type == 'video'" class="video">
                <img src="../../assets/link-url.png">
                {{ item.msgContent.videoName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { SensitiveWordsMonitor } from '@/api/sensitiveWordsMonitor'
export default {
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      page: 1,
      perPage: 15,
      chatRecord: [],
      showChatRecord: false
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    check (item) {
      SensitiveWordsMonitor.show({
        sensitiveWordMonitorId: item.sensitiveWordMonitorId
      }).then(res => {
        this.chatRecord = res.data
        this.showChatRecord = true
      })
    },
    onLoad () {
      this.loading = true
      SensitiveWordsMonitor.index({
        page: this.page,
        page_size: this.perPage
      }).then(res => {
        this.loading = false
        if(res.data.list.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        this.list = this.list.concat(res.data.list || [])
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.van-nav-bar__content{
  background: #2b63cd;
  color: #fff;
}
/deep/.van-nav-bar .van-icon{
  color: #fff;
}
/deep/.van-nav-bar__text{
  color: #fff;
}
/deep/.van-nav-bar__title{
  color: #fff;
}
/deep/.van-dialog__header {
  padding: 10px 0;
}


.index {
  font-size: 14px;
  height: 100%;
  

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 15px;
  }

  .fs13 {
    font-size: 12px;
  }

  .fs14 {
    font-size: 14px;
  }

  >.content {
    min-height: calc(100% - 46px);
    padding: 12px;
    background: #F1f2f5;
    // background: #fff;
    
    .list-item {
      background: #fff;
      margin-bottom: 12px;
      border-radius: 6px;
      padding: 12px;
      font-size: 14px;

      .intro {
        display: flex;
        margin-bottom: 10px;


        >div {
          flex: 1;
          text-align: center;
        }

        .label {
          margin-bottom: 6px;
          font-weight: 600;
        }

        .content {
          font-size: 12px;
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: right;
      text-align: right;
      border-top: 1px solid #F1f2f5;
      padding-top: 10px;

      >div:last-child {
        flex: 1;
        text-align: right;
      }
    }
  }

  .chatRecord {
    max-height: 60vh;
    overflow-y: auto;
    padding: 20px;

    .sendBox {
      margin-bottom: 16px;

      >div:first-child {
        font-size: 12px;
        margin-bottom: 6px;
      }

      .contcat {
        display: flex;

        span {
          flex: 1;
          text-align: right;
        }
      }

      .msg {
        background: #f1f1f1;
        padding: 6px 10px;
        border-radius: 8px;
        font-size: 12px;
        word-break:break-all;
        word-wrap:break-word;

        .link {
          display: flex;
          align-items: center;

          img {
            width: 50px;
            height: 50px;
            border-radius: 8px;
            margin-right: 6px;
          }

          p {
            margin-top: 10px;
            margin-bottom: 0;
            color: #999;
          }
        }

        .image {
           img {
             max-width: 100%;
             border-radius: 8px;
           }
        }
      }
    }
  }

}
</style>
